import React from "react"
import { Layout } from "../components/Layout"
import { Helmet } from "react-helmet"
import {
  CTAButton,
  AttributeSection,
  GetStartedButton,
  LearnMoreButton,
  Faq,
  CompareSection
} from "../commonComponents"
import {
  IntegrationsMiddleSectionImageLeft,
  IntegrationsMiddleSectionImageRight,
} from "../integrationsMiddleSection"
import {
  IntegrationDarkSection,
  IntegrationHeroSection,
} from "../integrationsUtils"
import { capitalizeFirstLetter } from "../utils"

export default function Intercom() {
  const SERVICE_NAME = "intercom"
  const MAIN_IMAGE_SRC = "/img/integration-intro-main-3.webp"
  const BACKGROUND_COLOR = "#f69fa8"

  return (
    <Layout
      keywords="Intercom integration, Intercom Chatbot, Intercom AI, chatbot for Intercom, Intercom AI chatbot"
      title="Enhance Your Customer Support With IrisAgent Intercom Chatbot Integration"
      description="Discover the power of IrisAgent's seamless integration with Intercom. Elevate your customer support to new heights with integrated communication and support solutions"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link rel="canonical" href="https://irisagent.com/intercom/" />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <IntegrationHeroSection
        serviceName={SERVICE_NAME}
        titleIntegration={
          <>
            Empower your {capitalizeFirstLetter(SERVICE_NAME)} <br/>agents
            and <span className="title-fucsia-color">deflect <br/>40% of chats</span> with<br/> Chatbot for Intercom
          </>
        }
        descriptionIntegration={
          <>
            <span >
              Resolve support conversations faster,
              <br/>improve agent performance, and deflect
              <br/>more than 40% of chats with IrisGPT
              <br/>and Intercom chatbot integration
            </span>

          </>
        }
        imageIntegration={MAIN_IMAGE_SRC}
        backgroundColorIntegration={BACKGROUND_COLOR}
      />
      {/* <!-- End Hero --> */}

      {/*  ======= Cta Section =======  */}
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Scale your{" "}
            <span className="title-fucsia-color">customer support team</span>
          </span>
        }
        button={
          <CTAButton
            to="https://www.intercom.com/app-store/?app_package_code=iris&search=irisagent"
            label={`FIND US ON ${SERVICE_NAME.toUpperCase()} MARKETPLACE`}
            target="_blank"
          />
        }
        firstColClassName="col-md-8"
        secondColClassName="col-md-4"
        buttonWebFlexAlignment="end"
      />
      {/*  End Cta Section  */}

      {/* <!-- ======= Middle Section ======= --> */}

      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/intercomMiddle1.webp"
        imageTextMiniature="/img/intercomMiniature.webp"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={
          <>
            Resolve common customer conversations faster with AI
          </>
        }
        descriptionTextContent={
          <>
            Access appropriate resolutions based on similar conversations and knowledge base articles with Intercom AI Agent Assist. The AI leverages natural language processing to understand and respond to customer inquiries more effectively.
          </>
        }
      />
      <IntegrationsMiddleSectionImageRight
        mainImage="/img/irisgpt.svg"
        imageTextMiniature="/img/intercomMiniature.webp"
        backgroundSectionColor="#F9F9F9"
        descriptionTextTitle={
          <>Deflect 40% of chats with Intercom AI Chatbot integration</>
        }
        descriptionTextContent={
          <>
            Trained on the world’s largest advanced large language models, IrisGPT generates multilingual, trustworthy, and highly accurate conversation answers integrated in your own Intercom platform and chatbot
          </>
        }
      />
      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/intercomMiddle3.webp"
        imageTextMiniature="/img/intercomMiniature.webp"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={
          <>Automate tagging, routing and prioritizing within Intercom</>
        }
        descriptionTextContent={
          <>
            IrisAgent discovers tags customized to your domain and automatically
            tags new conversations. Get rid of manual and inaccurate ticket tagging.
          </>
        }
      />
      <IntegrationsMiddleSectionImageRight
        mainImage="/img/intercomMiddle2.webp"
        imageTextMiniature="/img/intercomMiniature.webp"
        backgroundSectionColor="#F9F9F9"
        descriptionTextTitle={
          <>Add product context from DevOps tools to Intercom conversations</>
        }
        descriptionTextContent={
          <>
            Instant problem discovery using internal operational and product
            quality signals. Know the business priority and history of the customer and
            empathize with customer experience better. Link tickets to known product bugs in Jira.
          </>
        }
      />

      {/* <!-- End Middle Section --> */}

      {/* <!-- ======= Counts Section ======= --> */}
      <CompareSection theme="dark" service="Intercom"/>
      {/* <!-- End Counts Section --> */}

      {/* //   <!-- ======= Attribute Section ======= --> */}
      <AttributeSection />
      {/* //   <!-- ======= END -- Attribute Section ======= --> */}

                          <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="How does IrisAgent enhance customer service for Intercom customers?"
                  answer="IrisAgent enhances customer service by providing fast, accurate, and personalized responses across various communication channels. Its advanced capabilities and features, including AI Agent Assist enable it to comprehend human language, understand its nuances, and assist businesses in ensuring a seamless and cohesive brand experience for Intercom customers and their customers. By leveraging IrisAgent, businesses can significantly improve resolution rates and enhance overall customer relationships, contributing to a superior customer experience with personalized and accurate responses."
                />
                <Faq
                  id="faq2"
                  question="Can IrisAgent integrate with Intercom's existing AI capabilities?"
                  answer="Yes, IrisAgent seamlessly integrates with Intercom solutions platform, complementing its users' existing AI capabilities. Through this integration, Intercom customers can leverage the power of IrisAgent technology to enhance their customer engagement strategies and drive operational efficiency."
                />
                <Faq
                  id="faq3"
                  question="How does IrisAgent AI resolve support tickets in Intercom faster?"
                  answer="IrisAgent Agent Assist AI and IrisGPT in Intercom, powered by an advanced AI chatbot, saves agents’ and support team’s time and effort by automating responses to common, repetitive customer support queries, key to enabling faster ticket resolution. It also provides agents with real-time, context-aware response suggestions during customer conversations, enabling them to resolve complex issues faster and more accurately. Additionally, the AI chatbot can handle follow-up questions, allowing for more detailed and helpful customer interactions. This results in improved customer satisfaction and reduced ticket resolution times."
                />
                <Faq
                  id="faq4"
                  question="How does IrisAgent AI with natural language processing automate ticket tagging, chatbot answers, and sentiment analysis in Intercom?"
                  answer="IrisAgent automatically identifies tags specific to your domain or industry and applies them to new tickets without agent intervention. Our AI also analyzes Intercom chats for language cues that reveal the sentiment. IrisGPT with its webhook integration with Intercom chatbot finds accurate answers to customer queries by training AI bot on ticket and knowledge base data."
                />
                <Faq
                  id="faq5"
                  question="How can Intercom customers get started with IrisAgent?"
                  answer="It takes just a few minutes to get started with IrisAgent. Intercom customers can install the IrisAgent app from the Intercom marketplace and start using it right away. IrisAgent is designed to be user-friendly and intuitive, so you can start leveraging its advanced AI capabilities without any technical expertise. To automate Intercom with IrisAgent's AI, configure AI responses for specific triggers and actions, using custom bots, such as automated FAQs or conversation routing, within IrisAgent. Train the AI using historical conversation data to ensure it understands your business and customer queries accurately. Activate AI automation to let it handle customer interactions automatically. Regularly review and optimize the AI's performance to refine responses and improve the effectiveness of automation, enhancing customer support efficiency and personalizing user interactions at scale."
                />
                <Faq
                  id="faq6"
                  question="How does IrisGPT's Intercom AI work?"
                  answer="IrisGPT’s AI-Powered Chatbot is built using advanced AI languages and are able to answer questions easily and have full conversational capability with customers. It is designed to provide bespoke answers for a personalized customer experience, ensuring that every interaction feels unique and tailored. This may also dramatically lower the number of customer support queries. By integrating with the help center, the chatbot can access accurate and reliable information, ensuring that responses are always up to date. It employs advanced mechanisms to avoid incorrect answers and irrelevant answers, enhancing the reliability of customer interactions. The chatbot's capability to provide instant answers and handle natural language processing allows for more natural and engaging customer conversations. We are entering a new era of customer engagement with these advanced AI capabilities, where the chatbot not only improves resolution rates but also helps in identifying qualified leads, ensuring a better overall customer experience. Ensure reliable and relevant answers based only on a support page."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= Cta Section ======= --> */}
      {/* <ContactSection></ContactSection> */}
      {/* <!-- End Cta Section --> */}
    </Layout>
  )
}
